import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../layouts/de'
import Title from '../components/Titles/Title'
import SubSubTitle from '../components/Titles/SubSubTitle'
const lang = 'de'

export default class JobsPage extends React.Component {
  render() {
    const { data, location } = this.props
    const { jobs } = data

    function renderJobOffers() {
      const publishedJobs = jobs.edges.filter((j) => j.node.publish)
      if (publishedJobs.length === 0)
        return <p className="text-center">Keine Stellenangebote gefunden.</p>
      return publishedJobs.map(({ node: job }) => {
        const translations = job.translations.find((t) => t.language === lang)

        return (
          <Link to={`/de/jobs/${job.slug}`} key={job.id}>
            <div className="mx-10 mb-5 text-black sm:mx-20" key={job.id}>
              <div className="w-full p-4 bg-gray-200">
                <SubSubTitle>{translations.title}</SubSubTitle>
                <p
                  className="my-6"
                  dangerouslySetInnerHTML={{ __html: translations.description }}
                ></p>
                <div className="flex flex-wrap text-sm font-medium text-black uppercase font-text">
                  {job.time_scope && (
                    <div className="inline-flex px-3 py-1 mb-2 mr-2 bg-yellow-500 rounded-sm">
                      <StaticImage
                        src="../assets/img/clock.svg"
                        className="h-[20px] mr-1"
                        height={20}
                        alt=""
                      />
                      <span className="m-0">
                        {job.time_scope} Stunden/Woche
                      </span>
                    </div>
                  )}
                  {job.location && (
                    <div className="inline-flex px-3 py-1 mb-2 mr-2 bg-yellow-500 rounded-sm">
                      <StaticImage
                        src="../assets/img/location.svg"
                        className="h-[20px] mr-1"
                        height={20}
                        alt=""
                      />
                      <span className="m-0">{job.location}</span>
                    </div>
                  )}
                  {job.limitation && (
                    <div className="inline-flex px-3 py-1 mb-2 mr-2 bg-yellow-500 rounded-sm">
                      <StaticImage
                        src="../assets/img/handshake.svg"
                        className="h-[20px] mr-[5px]"
                        height={20}
                        alt=""
                      />
                      <span className="m-0">
                        {job.limitation ? 'Befristet' : 'Unbefristet'}
                      </span>
                    </div>
                  )}
                  {translations.start_date && (
                    <div className="inline-flex px-3 py-1 mb-2 mr-2 bg-yellow-500 rounded-sm">
                      <span className="m-0">
                        Start: {translations.start_date}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Link>
        )
      })
    }

    return (
      <Layout location={location}>
        <div className="relative">
          <Title>Jobs</Title>
          {jobs && jobs.length && <p>Keine offenen Stellen gefunden.</p>}
          {renderJobOffers()}
        </div>
      </Layout>
    )
  }
}

JobsPage.propTypes = {
  data: PropTypes.shape({
    jobs: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const pageQuery = graphql`
  query {
    jobs: allDirectusJob {
      edges {
        node {
          id
          slug
          limitation
          location
          time_scope
          publish
          translations {
            language
            title
            description
            start_date
          }
        }
      }
    }
  }
`
