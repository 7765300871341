/*
  H1 Heading with Line underneath
*/
import React from 'react'

const Title = ({ children, className }) => (
  <div className="ml-10 sm:ml-20">
    <h1
      className={`font-title font-bold text-3xl md:text-5xl mb-3 mt-20 pr-20 ${className}`}
    >
      {children}
    </h1>
    <div className="bg-black h-[4px] w-[30px] mb-10"></div>
  </div>
)

export default Title
